import Flickity from "react-flickity-component";
import React from "react";
import i1 from "../../../assets/img/nicolas_cropped.png";
import i18n from "../../../i18n/i18n";
import i2 from "../../../assets/img/ramil_cropped.jpg";
import i3 from "../../../assets/img/alyona_cropped.png";
import i4 from "../../../assets/img/magzhan_cropped.png";
import i5 from "../../../assets/img/sayasat_cropped.png";
import i6 from "../../../assets/img/olzhas_cropped.png";
import { withTranslation } from "react-i18next";

function ReviewsInfluential() {
	const people = [
		{
			img: i3,
			name: "Alena Tkachenko \n Cofounder, \n генеральный директор Nommi",
			review:
				"Я думаю, что это одна из возможностей, которую было бы обидно упустить. Научиться кодить быстро, в классной команде и с хорошими возможностями нетворка - это круто!",
		},
		{
			img: i4,
			name: "Магжан Мадиев \n Cofounder Naimi.kz \n Cofounder Astana Hub",
			review: "Школа alem станет колыбелью инноваций для Казахстана!",
		},
		{
			img: i6,
			name: "Олжас Сатиев \n Президент ЦАРКА \n Основатель WebTotem",
			review:
				"С учетом катастрофической нехватки IT кадров в Казахстане и слабым образованием в технических вузах, школа Alem имеет хорошие шансы стать одной из ведущих кузницей программистов. На самом деле, мы, представители IT компании, давно уже ждём такого игрока на рынке.",
		},
		{
			img: i5,
			name: "Саясат Нурбек \n Ғылым және жоғары білім министрі",
			review:
				"Бүгінгі қоғамда білім, дағдылар соңшалықты тез ескіреді, жаңа тұрпатты мектептерге, білім алаңдарына мұқтажбыз. Alem мектебі адамдарды IT саласына тарту арқылы әлеуметтік тұрғыда қолдауға арналған. Заманауи оқыту технологиялар, инклюзивті тәжірибелер мен әдістер, менторлық бағдарламалар арқылы әр адамды ең сұранысқа иә IT– мамандықтар мен жаңа біліммен қамтамасыз етеді. Өміріңіздегі екінші мүмкіндікті жіберіп алмаңыз!",
		},
	];

	return (
		<section className="section has-text-centered">
			<div className="container">
				<h3 className="subtitle is-3 has-text-weight-bold mb-6">
					<span className="line-above">{i18n.t("SayingAboutUs")}</span>
				</h3>
				<Flickity className="mb-6" groupCells={true} setGallerySize={false}>
					{people.map((val, idx) => (
						<div
							className="is-flex is-flex-direction-column is-align-items-center custom-is-width-max-420px px-5 mx-5"
							key={idx}
						>
							<img
								alt="carousel-img"
								src={val.img}
								className="image is-160x160 mb-2"
							/>
							<h4 className="has-text-weight-bold mb-3">{val.name}</h4>
							<p className="has-text-left">{val.review}</p>
						</div>
					))}
				</Flickity>
			</div>
		</section>
	);
}

export default withTranslation()(ReviewsInfluential);
