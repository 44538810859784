import "../../style/css/page-main.css";

import About from "./blocks/about";
import Brands from "./blocks/brands";
import Carousel from "./blocks/carousel";
import Graph from "./blocks/graph";
import HowItWorks from "./blocks/how-it-works";
import HowToEnter from "./blocks/how-to-enter";
import MainBlock from "./blocks/main-block";
import Partners from "./blocks/partners";
import Plan from "./blocks/plan";
import React from "react";
import ReviewsInfluential from "./blocks/reviews-influential";
import Tour from "./blocks/tour";

function Main() {
	return (
		<>
			<MainBlock />
			<About />
			<HowItWorks />
			{/* <Plan /> */}
			<Graph />
			<HowToEnter />
			<Carousel />
			{/* <Brands /> */}
			<ReviewsInfluential />
			<Partners />
			<Tour />
		</>
	);
}
export default Main;
