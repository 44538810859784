import React from "react";
import history from "../../utils/history";
import i18n from "../../i18n/i18n";
import logoTg from "../../assets/img/logo3d-telegram.png";
import { withTranslation } from "react-i18next";

function ModalAskQuestion() {
	document.addEventListener("DOMContentLoaded", () => {
		// Functions to open and close a modal
		function openModal($el) {
			$el.classList.add("is-active");
		}

		function closeModal($el) {
			$el.classList.remove("is-active");
		}

		function closeAllModals() {
			(document.querySelectorAll(".modal") || []).forEach(($modal) => {
				closeModal($modal);
			});
		}

		// Add a click event on buttons to open a specific modal
		(document.querySelectorAll(".js-modal-trigger") || []).forEach(
			($trigger) => {
				const modal = $trigger.dataset.target;
				const $target = document.getElementById(modal);

				$trigger.addEventListener("click", () => {
					openModal($target);
				});
			}
		);

		// Add a click event on various child elements to close the parent modal
		(
			document.querySelectorAll(
				".modal-background, .modal-close, .modal-card-head .delete, .modal-card-foot .button"
			) || []
		).forEach(($close) => {
			const $target = $close.closest(".modal");

			$close.addEventListener("click", () => {
				closeModal($target);
			});
		});

		// Add a keyboard event to close all modals
		document.addEventListener("keydown", (event) => {
			const e = event || window.event;

			if (e.keyCode === 27) {
				// Escape key
				closeAllModals();
			}
		});
	});

	return (
		<div id="modal-ask-qustion" className="modal">
			<div className="modal-background"></div>

			<div className="modal-content">
				<div className="box is-radiusless">
					<h3 className="subtitle is-4">{i18n.t("WarningAskQuestionTitle")}</h3>
					<p className="mb-4 has-text-danger-dark">
						{i18n.t("WarningAskQuestionText")}
					</p>
					<hr className="mb-3" />
					<button
						onClick={() => {
							history.push("/faq");
							document
								.getElementById("modal-ask-qustion")
								.classList.remove("is-active");
						}}
						className="button is-primary mr-2 mb-2"
					>
						{i18n.t("BtnGoToFAQ")}
					</button>
					<a
						href="https://t.me/alem_support"
						className="button is-primary is-light"
						target="_blank"
						rel="noreferrer"
					>
						<img alt="tg-icon" src={logoTg} className="icon" />
						<p>{i18n.t("BtnAskQuestionSubmit")}</p>
					</a>
				</div>
			</div>

			<button className="modal-close is-large" aria-label="close"></button>
		</div>
	);
}

export default withTranslation()(ModalAskQuestion);
