import React from "react";
import i18n from "../../i18n/i18n";
import logo from "../../assets/img/alem.school.svg";
import logoTg from "../../assets/img/logo3d-telegram.png";
import { withTranslation } from "react-i18next";

function Footer() {
	return (
		<footer className="footer">
			<div className="container">
				<div className="columns">
					<div className="column">
						<h4 className="has-text-weight-bold">Follow us</h4>
						<ul>
							<li>
								<a
									href="https://www.youtube.com/channel/UCXMzkC011qaidxJx3-XdTEw"
									target="_blank"
									rel="noreferrer"
									className="has-text-grey-dark"
								>
									YouTube
								</a>
							</li>
							<li>
								<a
									rel="noreferrer"
									href="https://www.instagram.com/hello.alem/"
									target="_blank"
									className="has-text-grey-dark"
								>
									Instagram
								</a>
							</li>
							<li>
								<a
									rel="noreferrer"
									href="https://t.me/alem_school"
									target="_blank"
									className="has-text-grey-dark"
								>
									Telegram
								</a>
							</li>
							<li>
								<a
									rel="noreferrer"
									href="https://www.facebook.com/helloalem1"
									target="_blank"
									className="has-text-grey-dark"
								>
									Facebook
								</a>
							</li>
						</ul>
					</div>
					<div className="column">
						<h4 className="has-text-weight-bold">Visit</h4>
						<ul>
							<li>
								<a
									href="https://go.2gis.com/7b4x9"
									className="has-text-grey-dark"
								>
									{i18n.t("school_address_astana")}
								</a>
							</li>
							<li className="mb-2">
								<a
									href="mailto:info@alem.school"
									className="has-text-grey-dark"
								>
									info@alem.school
								</a>
							</li>
							<li>
								<a
									href="https://forms.office.com/Pages/ResponsePage.aspx?id=UI3UNgBIOkCSIkT2ccij5OTU12ZmScFCtWvLzqM2KcNUOVVKVFdIODRHUVJLSUFZWDE3UEI2MFhDTS4u"
									className="button is-primary"
									target="_blank"
									rel="noreferrer"
								>
									{i18n.t("excursion")}
								</a>
							</li>
						</ul>
					</div>
					<div className="column">
						<ul>
							<li className="mb-2">
								<a href="/">
									<img
										src={logo}
										alt="alem school logo"
										width="112"
										height="28"
										className="img-invert"
									/>
								</a>
							</li>
							<li>
								<button
									className="js-modal-trigger button is-primary"
									data-target="modal-ask-qustion"
								>
									<img alt="tg-icon" src={logoTg} className="icon" />
									<p>{i18n.t("BtnAskQuestion")}</p>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</footer>
	);
}

export default withTranslation()(Footer);
