import React from "react";
// import i1 from "../../../assets/img/interview.jpg";
import i18n from "../../../i18n/i18n";
import iconcard from "../../../assets/lordicons/734-id-business-card-1-outline.png";
import iconcontroller from "../../../assets/lordicons/186-puzzle-outline.png";
import iconconversation from "../../../assets/lordicons/981-consultation-outline.png";
import icondisplay from "../../../assets/lordicons/478-computer-display-outline.png";
import iconfile from "../../../assets/lordicons/56-document-outline.png";
import iconswimming from "../../../assets/lordicons/663-swimming-outline.png";
import { withTranslation } from "react-i18next";

function HowToEnter() {
	return (
		<>
			<section className="section has-background-black has-text-white">
				<div className="container py-6">
					<div className="has-text-left has-text-centered-mobile mb-6">
						<h2 className="title has-text-white">
							<span className="line-above">04</span>
						</h2>
						<h3 className="subtitle is-3 has-text-white">
							{i18n.t("HTA_title")}
						</h3>
					</div>
					<div className="how_to_enter__steps column">
						<div className="how_to_apply__step">
							<h2 className="how_to_apply__step__number">1</h2>
							<img
								alt="PlanIcon"
								src={iconcard}
								className="how_to_apply__step__image"
							/>
							<div className="how_to_apply__step__inner_block">
								<h3 className="how_to_apply__step__inner_block__title">
									{i18n.t("HTA_subtitle-1")}
								</h3>
								<p>{i18n.t("HTA_text-1")}</p>
							</div>
						</div>
						<div className="how_to_apply__step">
							<h2 className="how_to_apply__step__number">2</h2>
							<img
								alt="PlanIcon"
								src={iconcontroller}
								className="how_to_apply__step__image"
							/>
							<div className="how_to_apply__step__inner_block">
								<h3 className="how_to_apply__step__inner_block__title">
									{i18n.t("HTA_subtitle-2")}
								</h3>
								<p>{i18n.t("HTA_text-2")}</p>
							</div>
						</div>
						<div className="how_to_apply__step">
							<h2 className="how_to_apply__step__number">3</h2>
							<img
								alt="PlanIcon"
								src={icondisplay}
								className="how_to_apply__step__image"
							/>
							<div className="how_to_apply__step__inner_block">
								<h3 className="how_to_apply__step__inner_block__title">
									{i18n.t("HTA_subtitle-3")}
								</h3>
								<p>{i18n.t("HTA_text-3")}</p>
							</div>
						</div>
						<div className="how_to_apply__step">
							<h2 className="how_to_apply__step__number">4</h2>
							<img
								alt="PlanIcon"
								src={iconfile}
								className="how_to_apply__step__image"
							/>
							<div className="how_to_apply__step__inner_block">
								<h3 className="how_to_apply__step__inner_block__title">
									{i18n.t("HTA_subtitle-4")}
								</h3>
								<p>{i18n.t("HTA_text-4")}</p>
							</div>
						</div>
						<div className="how_to_apply__step">
							<h2 className="how_to_apply__step__number">5</h2>
							<img
								alt="PlanIcon"
								src={iconswimming}
								className="how_to_apply__step__image"
							/>
							<div className="how_to_apply__step__inner_block">
								<h3 className="how_to_apply__step__inner_block__title">
									{i18n.t("HTA_subtitle-5")}
								</h3>
								<p>{i18n.t("HTA_text-5")}</p>
							</div>
						</div>
					</div>
					<div className="is-flex is-justify-content-center mt-4">
						<a
							href="https://platform.alem.school"
							className="button is-primary is-outlined has-text-white"
							target="_blank"
							rel="noreferrer"
						>
							{i18n.t("Поступить")}
						</a>
					</div>
				</div>
			</section>
		</>
	);
}

export default withTranslation()(HowToEnter);
