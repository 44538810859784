import React from "react";
import Typed from "react-typed";
import i18n from "../../../i18n/i18n";
import { withTranslation } from "react-i18next";

function MainBlock() {
  const options = {
    strings: [
      "education",
      "you",
      "development",
      "collaboration",
      "competition",
    ],
  };

  //   const typed = new Typed(".typing_text", options);
  return (
    <>
      <section className="hero is-fullheight is-black">
        <div className="hero-body">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column hero-content">
                <h1 className="title is-1">alem</h1>
                <h2 className="subtitle is-4">
                  next level of{" "}
                  <span>
                    <Typed
                      strings={options.strings}
                      typeSpeed={60}
                      loop={true}
                    />
                  </span>
                </h2>
                <p className="has-text-orange"><b>{i18n.t("dates1")}</b></p>
                <p className="has-text-orange">{i18n.t("datesReg1")}</p>
                <br />
                <p className="has-text-orange"><b>{i18n.t("dates2")}</b></p>
                <br />
                <a
                  href="https://platform.alem.school"
                  className="button is-primary is-outlined has-text-white"
                >
                  {i18n.t("Поступить")}
                </a>
              </div>
              <div className="column is-flex is-justify-content-center is-align-items-center">
                <div className="circle"></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default withTranslation()(MainBlock);
