import "./i18n/i18n";
import "./style/scss/bulma-style.scss";
import "./style/css/fonts.css";
import "./style/css/styles.css";

import React, { useEffect, useState } from "react";

import Footer from "./components/common-blocks/footer";
import ModalAskQuestion from "./components/common-blocks/modal-ask-question";
import Navigation from "./components/common-blocks/navigation";
// import Popup from "./components/common-blocks/popup";
import Routes from "./routes";

function App() {
  // const [showPopup, setShowPopup] = useState(false);

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setShowPopup(true);
  //   }, 4000);

  //   return () => clearTimeout(timer);
  // }, []);

  return (
    <>
      <Navigation />
      <Routes />
      <ModalAskQuestion />
      <Footer />
      {/* {showPopup && <Popup />} */}
    </>
  );
}

export default App;
