import React from "react";
import i5 from "../../../assets/img/forbes-kz.svg";
import i6 from "../../../assets/img/nur-kz.svg";
import i7 from "../../../assets/img/steppe.svg";
import logo_5q from "../../../assets/img/logo-5qmedia.svg";
import logo_kana from "../../../assets/img/logo-kana-beisekeyev.jpg";
import { withTranslation } from "react-i18next";

function Brands() {
	return (
		<>
			<section className="section">
				<div className="container">
					<div className="pt-6 pb-4">
						<div className="level">
							<div className="level-item mb-5">
								<a
									title="Forbes.kz"
									href="https://forbes.kz/news/2019/07/11/newsid_203451"
									target="_blank"
									rel="noreferrer"
								>
									<img
										alt="forbes.kz"
										className="custom-is-height-32px"
										src={i5}
									/>
								</a>
							</div>
							<div className="level-item mb-5">
								<a
									title="NUR.kz"
									href="https://www.nur.kz/1804409-skola-budusego-otkroetsa-na-baze-astana-hub.html"
									target="_blank"
									rel="noreferrer"
								>
									<img
										alt="nur.kz"
										className="custom-is-height-32px"
										src={i6}
									/>
								</a>
							</div>
							<div className="level-item mb-5">
								<a
									title="STEPPE"
									href="https://the-steppe.com/razvitie/shkola-budushchego-dlya-kogo-sozdana-shkola-programmirovaniya-alem-i-kak-tuda-popast"
									target="_blank"
									rel="noreferrer"
								>
									<img
										alt="the-steppe.com"
										className="custom-is-height-32px"
										src={i7}
									/>
								</a>
							</div>
						</div>
						<div className="level">
							<div className="level-item mb-5">
								<a
									title="WE Project"
									href="https://weproject.media/articles/detail/alem-besplatnaya-shkola-programmirovaniya-v-kazakhstane-kotoraya-rabotaet-24-chasa-v-sutki/"
									target="_blank"
									rel="noreferrer"
								>
									<img
										alt="weproject"
										src="https://weproject.media/bitrix/templates/slonworks/img/footerlogo.png"
										className="custom-is-height-32px"
									/>
								</a>
							</div>
							<div className="level-item mb-5">
								<a
									title="5q-media"
									href="https://5q.media/2022/02/28/vmesto-vuza-kak-alem-school-besplatno-obuchaet-programmistov/"
									target="_blank"
									rel="noreferrer"
								>
									<img
										alt="5q-media"
										src={logo_5q}
										className="custom-is-height-32px"
									/>
								</a>
							</div>
							<div className="level-item mb-5">
								<a
									title="kana beisekeyev"
									href="https://www.youtube.com/watch?v=VGBf8rqERNw"
									target="_blank"
									rel="noreferrer"
								>
									<img
										alt="5q-media"
										src={logo_kana}
										className="custom-is-height-32px"
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	);
}

export default withTranslation()(Brands);
