import React from "react";
import i18n from "../../../i18n/i18n";
import { withTranslation } from "react-i18next";

function Tour() {
	return (
		<section className="section has-background-black has-text-white">
			<div className="container">
				<div className="is-flex is-flex-direction-column is-align-items-center">
					<h4 className="is-size-6 has-text-white has-text-weight-bold has-text-centered">
						{i18n.t("партнеры1")}
					</h4>
					<iframe
						title="tour"
						width="100%"
						height="400"
						style={{
							width: "100%",
							height: "400px",
							border: "none",
							maxWidth: "720px",
						}}
						frameBorder={0}
						allowFullScreen={true}
						allow="vr,gyroscope,accelerometer,fullscreen"
						scrolling="no"
						src="https://kuula.co/share/collection/7lQSP?fs=1&vr=1&sd=1&initload=0&thumbs=1&chromeless=1&logo=0"
					></iframe>
				</div>
			</div>
		</section>
	);
}

export default withTranslation()(Tour);
